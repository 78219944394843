
import styles from './Greeting.module.scss';

function Greeting() {
    return (
        <>
            <div className="components_wrap">
                <div className="cont_img_wrap">
                    <img src="/img/greeting8thEng.png" className='cont_img_de' alt="" style={{ width: '100%', }} />
                    <img src="/img/greeting8thEng.png" className='cont_img_m' alt="" style={{ width: '100%', }} />
                </div>
                <div className="cont_wrap">
                    <div className="cont_tit_box">
                        <span>Greeting</span>
                    </div>
                    {/* <div className="cont_box">
                        <p>The National Academy of Engineering of Korea aims to discover and acknowledge engineers who have made remarkable contributions to the technological development in universities, companies, and research institutes, and to contribute to Korea’s creative engineering technology development through academic research and supporting projects.</p>
                        <p>We will put together our knowledge for the innovation of Korean industry and engineering technology. As a leading group of engineering technology, NAEK will be a faithful mediator between government and society.</p>
                        <p>In addition, NAEK will propose effective policies on social issues related to industrial and engineering and technology and create sound public opinion.</p>
                        <p>The future of Korea depends on fostering commendable talents in science and technology. NAEK will promote its program to foster talent, including the Junior Engineering Technology Classes, the Campus Patent Strategy Universiade, and the Young Engineers Honor Society. NAEK will share its vision of the future with students and create an environment where engineers can contribute to the country as proud leaders in many areas.</p>
                        <p>With a vision of a global engineering technology platform, NAEK will play a leading role in creating a sustainable and growing society, a creative and smart society, and a healthy and safe society.</p>
                        <p>We appreciate your interest and continuous support.</p>
                    </div> */}
                    <div className="cont_box">
                        <p>The National Academy of Engineering of Korea (NAEK) is dedicated to identifying and honoring engineers who have made outstanding contributions to technological advancement across academia, industry, and research institutes. Through academic research and support programs, NAEK seeks to drive Korea’s creative engineering and technology development.</p>

                        <p>We will pool our collective knowledge to spur innovation in Korean industry and engineering. As a leading organization in engineering technology, NAEK aims to serve as a reliable bridge between government and society.</p>

                        <p>Moreover, NAEK will actively propose effective policies addressing industrial and technological issues and help shape informed public opinion.</p>

                        <p>The future of Korea lies in nurturing exceptional talent in science and technology. NAEK promotes initiatives such as the Junior Engineering Technology Classes, the Campus Patent Strategy Universiade, and the Young Engineers Honor Society to foster the next generation. We will share our vision with students and create an environment where engineers can proudly contribute as leaders across various fields.</p>

                        <p>With the vision of becoming a global engineering technology platform, NAEK strives to play a leading role in building a sustainable, innovative, and safe society.</p>

                        <p>We deeply appreciate your interest and continued support.</p>
                    </div>

                </div>
            </div>
        </>
    );
}

export default Greeting;